// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private } from "@redwoodjs/router"

const Routes = () => {
  return (
    <Router>
      <Private unauthenticated="home">
        <Route
          path="/reports/610-past-due-storage-orders"
          page={PastDueStorageOrdersPage}
          name="pastDueStorageOrders"
        />
        <Route
          path="/reports/600-current-storage-orders"
          page={CurrentStorageOrdersPage}
          name="currentStorageOrders"
        />
        <Route
          path="/activities/{id:Int}"
          page={ActivityPage}
          name="activity"
        />
        <Route
          path="/rates/outboard-storage-rate/update"
          page={UpdateOutboardStorageRatePage}
          name="updateOutboardStorageRate"
        />
        <Route
          path="/rates/storage-rate/update"
          page={UpdateStorageRatePage}
          name="updateStorageRate"
        />
        <Route
          path="/rates/prep-paint-shrink-rates/update"
          page={UpdatePrepPaintShrinkRatePage}
          name="updatePrepPaintShrinkRate"
        />
        <Route
          path="/rates/hauling-rates/update"
          page={UpdateHaulingRatePage}
          name="updateHaulingRate"
        />
        <Route path="/rates" page={RatesPage} name="rates" />
        <Route path="/locations/{name}" page={LocationPage} name="location" />
        <Route path="/places/{id:Int}/{slug}" page={PlacePage} name="place" />
        <Route path="/places" page={PlacesPage} name="places" />
        <Route
          path="/customers/{id:Int}/update"
          page={UpdateCustomerPage}
          name="updateCustomer"
        />
        <Route
          path="/customers/{id:Int}/{slug}"
          page={CustomerPage}
          name="customer"
        />
        <Route
          path="/customers/new"
          page={CreateCustomerPage}
          name="createCustomer"
        />
        <Route path="/customers" page={CustomersPage} name="customers" />
        <Route
          path="/moorings/{id:Int}/update"
          page={UpdateMooringPage}
          name="updateMooring"
        />
        <Route
          path="/customers/{customerId:Int}/moorings/create"
          page={CreateMooringPage}
          name="createMooring"
        />
        <Route
          path="/motors/{id:Int}/update"
          page={UpdateMotorPage}
          name="updateMotor"
        />
        <Route
          path="/customers/{customerId:Int}/motors/create"
          page={CreateMotorPage}
          name="createMotor"
        />
        <Route
          path="/rigs/{id:Int}/update"
          page={UpdateRigPage}
          name="updateRig"
        />
        <Route
          path="/customers/{customerId:Int}/rigs/create"
          page={CreateRigPage}
          name="createRig"
        />
        <Route
          path="/trailers/{id:Int}/update"
          page={UpdateTrailerPage}
          name="updateTrailer"
        />
        <Route
          path="/customers/{customerId:Int}/trailers/create"
          page={CreateTrailerPage}
          name="createTrailer"
        />
        <Route
          path="/boats/{id:Int}/update"
          page={UpdateBoatPage}
          name="updateBoat"
        />
        <Route
          path="/customers/{customerId:Int}/boats/create"
          page={CreateBoatPage}
          name="createBoat"
        />
        <Route
          path="/storage-orders/{id:Int}/update"
          page={UpdateStorageOrderPage}
          name="updateStorageOrder"
        />
        <Route
          path="/units/{unitId:Int}/storage-orders/create"
          page={CreateStorageOrderPage}
          name="createStorageOrder"
        />
        <Route
          path="/units/{id:Int}/{slug}/update"
          page={UpdateUnitPage}
          name="updateUnit"
        />
        <Route path="/units/{id:Int}/{slug}" page={UnitPage} name="unit" />
        <Route path="/units" page={UnitsPage} name="units" />
      </Private>
      <Route path="/" page={PlacesPage} name="home" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
