import { AuthProvider } from "@redwoodjs/auth"
import netlifyIdentity from "netlify-identity-widget"
import ReactDOM from "react-dom"
import { RedwoodProvider, FatalErrorBoundary } from "@redwoodjs/web"
import FatalErrorPage from "src/pages/FatalErrorPage"

import Routes from "src/Routes"

import "./index.css"

netlifyIdentity.init()

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <AuthProvider client={netlifyIdentity} type="netlify">
      <RedwoodProvider>
        <Routes />
      </RedwoodProvider>
    </AuthProvider>
  </FatalErrorBoundary>,
  document.getElementById("redwood-app")
)
